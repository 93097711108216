<template>
  <div>
    <header
      class="topbar is_stuck"
      :style="{ position: 'fixed', top: ' 0px', width: '100%' }"
    >
      <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <div class="navbar-header">
          <a class="navbar-brand" href="javascript:void(0)">
            <b>
              <img
                src="@/assets/img/logo.png"
                style="width: 34px"
                alt="homepage"
                class="dark-logo"
              />
              <img
                src="@/assets/img/logo.png"
                style="width: 34px"
                alt="homepage"
                class="light-logo"
              />
            </b>
          </a>
        </div>
        <div class="navbar-collapse">
          <ul class="navbar-nav mr-auto mt-md-0">
            <li class="nav-item">
              <a
                class="
                  nav-link nav-toggler
                  hidden-md-up
                  text-muted
                  waves-effect waves-dark
                "
                href="javascript:void(0)"
                @click="expandMenuMobile"
              >
                <i :class="isExpand ? 'ti-menu' : 'ti-close'"></i>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="
                  nav-link
                  sidebartoggler
                  hidden-sm-down
                  text-muted
                  waves-effect waves-dark
                "
                href="javascript:void(0)"
                @click="expandMenu"
              >
                <i
                  :class="
                    isExpand
                      ? 'icon-arrow-left-circle ti-menu'
                      : 'icon-arrow-left-circle'
                  "
                ></i>
              </a>
            </li>
            <!-- <li class="nav-item dropdown">
              <a
                class="
                  nav-link
                  dropdown-toggle
                  text-muted text-muted
                  waves-effect waves-dark
                "
                href
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span>
                  <span class="badge badge-info"
                    ><i class="mdi mdi-bell"></i
                    >{{
                      counterNotification > 0 ? counterNotification : ""
                    }}</span
                  >
                  <div class="notify" v-if="counterNotification > 0">
                    <span class="heartbit"></span>
                    <span class="point"></span>
                  </div>
                </span>
              </a>
              <div class="dropdown-menu mailbox animated bounceInDown">
                <ul>
                  <li>
                    <div class="drop-title">Notifications {{ this.$store.state.notifCount }}</div>
                  </li>
                  <li>
                    <div class="message-center" v-if="headerNotif.length">
                      <a
                        exact
                        v-for="notif in headerNotif"
                        @click="goToDetail(notif)"
                        v-bind:key="notif.id"
                      >
                        <div class="btn btn-danger btn-circle">
                          <i class="fa fa-link"></i>
                        </div>
                        <div style="margin-left: 15px" class="mail-contnet">
                          <h5>{{ notif.title }}</h5>
                          <span class="mail-desc">{{ notif.description }}</span>
                          <span class="time">{{ notif.time }}</span>
                        </div>
                      </a>
                    </div>
                    <a
                      class="nav-link text-center"
                      href="javascript:void(0);"
                      v-else
                    >
                      <strong>Empty notifications</strong>
                    </a>
                  </li>
                  <li v-if="headerNotif.length">
                    <router-link
                      to="/notification-detail"
                      class="nav-link text-center"
                      href="javascript:void(0);"
                    >
                      <strong>Check all notifications</strong>
                      <i
                        style="margin-left: 10px"
                        class="fa fa-angle-right"
                      ></i>
                    </router-link>
                  </li>
                </ul>
              </div>
            </li> -->
            <li class="nav-item hidden-md-down">
              <a
                class="
                  nav-link
                  dropdown-toggle
                  text-muted
                  waves-effect waves-dark
                "
                href="#"
              >
                Anda login sebagai : {{ currentRole }}
              </a>
            </li>
          </ul>
          <ul class="navbar-nav my-lg-0">
            <li class="nav-item hidden-sm-down"></li>
            <li class="nav-item dropdown">
              <a
                class="
                  nav-link
                  dropdown-toggle
                  text-muted
                  waves-effect waves-dark
                "
                href
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  class="profile-pic"
                  v-if="user"
                  :src="user.avatar"
                  style="width: 50px; height: 50px; background: black"
                  alt="user"
                />
              </a>
              <div class="dropdown-menu dropdown-menu-right animated flipInY">
                <ul class="dropdown-user">
                  <li>
                    <div class="dw-user-box">
                      <div class="u-img">
                        <img
                          v-if="user"
                          :src="user.avatar"
                          style="width: 100px; height: 100px"
                          alt="user"
                        />
                      </div>
                      <div class="u-text">
                        <h4>{{ user.name }}</h4>
                        <p class="text-muted">{{ user.email }}</p>
                        <router-link
                          to="/profile"
                          class="btn btn-rounded btn-danger btn-sm"
                          >View Profile</router-link
                        >
                      </div>
                    </div>
                  </li>
                  <li role="separator" class="divider"></li>
                  <h5 class="dropdown-header">Unit</h5>
                  <li v-for="unit in user.units" v-bind:key="unit.id">
                    <a
                      href="javascript:void(0)"
                      @click="changeUnit(unit.id)"
                      :class="{ 'unit active': activeUnit(unit.id) }"
                    >
                      <i class="ti-user"></i>
                      {{ unit.akronim }}
                      <span v-if="activeUnit(unit.id)">(active)</span>
                    </a>
                  </li>
                  <li role="separator" class="divider"></li>
                  <h5 class="dropdown-header">Role</h5>
                  <li v-for="ro in user.role" v-bind:key="ro.id">
                    <a href="javascript:void(0)">
                      <i class="ti-user"></i>
                      {{ ro.name }}
                    </a>
                  </li>
                  <li role="separator" class="divider"></li>
                  <li>
                    <a href="javascript:void(0)" @click="logout">
                      <i class="fa fa-power-off"></i> Logout
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="
                  nav-link
                  dropdown-toggle
                  text-muted
                  waves-effect waves-dark
                "
                href
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
              </a>
              <div
                class="dropdown-menu dropdown-menu-right animated bounceInDown"
              >
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="onChangeLanguage('id')"
                >
                  <i class="flag-icon flag-icon-id"></i> Indonesia
                </a>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="onChangeLanguage('en')"
                >
                  <i class="flag-icon flag-icon-us"></i> Inggris
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
    <aside
      class="left-sidebar"
      :style="{ overflow: 'visible', position: !isExpand ? 'fixed' : '' }"
    >
      <div
        class="slimScrollDiv"
        style="position: relative; overflow: visible; width: auto; height: 100%"
      >
        <div
          class="scroll-sidebar"
          id="style-1"
          :style="{
            width: 'auto',
            height: '100%',
            overflow: styleSheet,
            marginBottom: '50px',
          }"
        >
          <div class="user-profile">
            <div class="profile-img">
              <img
                v-if="user"
                :src="user.avatar"
                style="width: 50px; height: 50px"
                alt="user"
              />
            </div>
            <div class="profile-text">
              <a
                href="#"
                class="dropdown-toggle link u-dropdown"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="true"
              >
                {{ user.name }}
                <span class="caret"></span>
              </a>
              <div class="dropdown-menu animated flipInY">
                <router-link to="/profile" class="dropdown-item">
                  <i class="ti-user"></i> My Profile
                </router-link>
                <div class="dropdown-divider"></div>
                <button @click="logout" class="dropdown-item">
                  <i class="fa fa-power-off"></i> Logout
                </button>
              </div>
            </div>
          </div>
          <nav class="sidebar-nav">
            <droplist :data="menu" />
            <!-- <hr />
            <a href="javascript:void(0)" class="link" @click="sisminkada"
              >&nbsp;&nbsp;<i class="mdi mdi-email"></i> Database Sisminkada </a
            >&nbsp;<br /><br /><br />
            <a href="javascript:void(0)" class="link" @click="meeting_room"
              >&nbsp;&nbsp;<i class="mdi mdi-email"></i> Meeting Room </a
            >&nbsp;<br /><br /><br />
            <a href="javascript:void(0)" class="link" @click="penghargaan"
              >&nbsp;&nbsp;<i class="mdi mdi-email"></i> Penghargaan </a
            >&nbsp;<br /><br /><br />
            <a href="javascript:void(0)" class="link" @click="bend26a"
              >&nbsp;&nbsp;<i class="mdi mdi-email"></i> Bend26a </a
            >&nbsp;<br /><br /><br />
            <a href="javascript:void(0)" class="link" @click="pustaka"
              >&nbsp;&nbsp;<i class="mdi mdi-email"></i> Pustaka </a
            >&nbsp;<br /><br /><br />
            <a href="javascript:void(0)" class="link" @click="web_mail"
              >&nbsp;&nbsp;<i class="mdi mdi-email"></i> Web Mail </a
            >&nbsp;<br /><br /><br />
            <a href="javascript:void(0)" class="link" @click="lampiran_skp"
              >&nbsp;&nbsp;<i class="mdi mdi-email"></i> Lampiran SKP </a
            >&nbsp;<br /><br /><br /> -->
          </nav>
        </div>
      </div>
      <div class="sidebar-footer">
        <!-- <a
          href="javascript:void(0)"
          v-if="!isAdmin"
          v-on:click="showAlert"
          class="link"
        >
          <div class="tooltip-button">
            <i class="ti-settings"></i>
            <span class="tooltiptext">Settings</span>
          </div>
        </a>
        <router-link to="/settings" v-else class="link">
          <div class="tooltip-button">
            <i class="ti-settings"></i>
            <span class="tooltiptext">Settings</span>
          </div>
        </router-link> -->
        <router-link to="/sisminkada">
          <div class="tooltip-button">
            <i class="ti-settings"></i>
            <span class="tooltiptext">Sisminkada</span>
          </div>
        </router-link>
        <!-- <a href="javascript:void(0)" class="link" @click="logout">
          <div class="tooltip-button">
            <i class="mdi mdi-power"></i>
            <span class="tooltiptext">Logout</span>
          </div>
        </a> -->
      </div>
    </aside>
  </div>
</template>

<script>
import axios from "axios";
import droplist from "../droplist/index";

export default {
  name: "Header",
  components: {
    droplist,
  },
  computed: {
    counterNotification() {
      return this.$store.state.notifCount;
    },
    checkNotifActive() {
      return this.$store.state.headerNotif.find(this.findStatus);
    },
    headerNotif() {
      return this.$store.state.headerNotif;
    },
    base() {
      return this.baseUrl;
    },
    user() {
      return this.$store.state.profile.user;
    },
    currentRole() {
      let role = "";
      let user = this.user;
      for (let us in user.units) {
        if (user.units[us].id == user.unit_id) {
          role = user.units[us].akronim;
        }
      }
      return role;
    },
    isAdmin() {
      let admin = false;
      for (let us in this.user.role) {
        if (this.user.role[us].id === 1) {
          admin = true;
        }
      }
      return admin;
    },
  },
  data() {
    return {
      isExpand: false,
      changeLogo: "",
      heightMobile: "98%",
      styleSheet: "auto",
      message: "",
      window: {
        width: 0,
        height: 0,
      },
      menu: [
        {
          title: "menu_list",
          path: "",
          name: "",
          icon: "",
          hasSubmenu: false,
          nameTag: true,
          cssClass: "nav-small-cap",
          items: [],
        },
        {
          title: "Beranda",
          path: "/",
          name: "",
          nameTag: true,
          icon: "mdi mdi-home",
          hasSubmenu: false,
          cssClass: "",
          items: [],
        },
        // {
        //   title: "document_in",
        //   path: "/documentIn",
        //   nameTag: false,
        //   name: "",
        //   icon: "mdi mdi-email",
        //   hasSubmenu: true,
        //   cssClass: "",
        //   items: [],
        // },
        // {
        //   title: "document_in",
        //   path: "/documentIn",
        //   icon: "mdi mdi-email",
        //   hasSubmenu: false,
        //   nameTag: false,
        //   badge: "document_in",
        //   name: "document_in",
        //   items: [],
        // },
        // {
        //   title: "document_sent",
        //   path: "/documentInSended",
        //   icon: "",
        //   badge: "document_in_send",
        //   name: "document_in",
        //   hasSubmenu: false,
        //   nameTag: false,
        //   items: [],
        // },
        {
          title: "document_in",
          path: "",
          nameTag: false,
          name: "",
          icon: "mdi mdi-email",
          hasSubmenu: true,
          items: [
            {
              title: "document_list",
              path: "/documentIn",
              icon: "",
              name: "document_in",
              badge: "document_in",
              nameTag: false,
              hasSubmenu: false,
              items: [],
            },
            {
              title: "document_already_sent",
              path: "/documentInAlreadySend",
              icon: "",
              badge: "document_in_already",
              name: "document_in",
              hasSubmenu: false,
              nameTag: false,
              items: [],
            },
            {
              title: "document_sent",
              path: "/documentInSended",
              icon: "",
              badge: "document_in_send",
              name: "document_in",
              hasSubmenu: false,
              nameTag: false,
              items: [],
            },
            {
              title: "document_rejected",
              path: "/documentInRejected",
              icon: "",
              badge: "document_in_reject",
              name: "document_in",
              nameTag: false,
              hasSubmenu: false,
              items: [],
            },
          ],
        },
        // {
        //   title: "disposition_in",
        //   path: "/dispositionIn",
        //   icon: "mdi mdi-file-outline",
        //   hasSubmenu: false,
        //   nameTag: false,
        //   badge: "disposition",
        //   name: "disposition_in",
        //   items: [],
        // },
        // {
        //   title: "disposition_sent",
        //   path: "/dispositionSend",
        //   icon: "",
        //   name: "disposition_in",
        //   badge: "disposition_send",
        //   hasSubmenu: false,
        //   nameTag: false,
        //   items: [],
        // },
        {
          title: "disposition",
          path: "",
          nameTag: false,
          name: "",
          icon: "mdi mdi-file-outline",
          hasSubmenu: true,
          items: [
            {
              title: "disposition_in",
              path: "/dispositionIn",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              badge: "disposition",
              name: "disposition_in",
              items: [],
            },
            // {
            //   title: "disposition_already_sent",
            //   path: "/dispositionAlreadySend",
            //   icon: "",
            //   badge: "disposition_already",
            //   name: "disposition_in",
            //   hasSubmenu: false,
            //   nameTag: false,
            //   items: [],
            // },
            {
              title: "disposition_sent",
              path: "/dispositionSend",
              icon: "",
              name: "disposition_in",
              badge: "disposition_send",
              hasSubmenu: false,
              nameTag: false,
              items: [],
            },
            {
              title: "Disposisi Dikembalikan",
              path: "/dispositionBack",
              icon: "",
              name: "disposition_in",
              badge: "disposition_back",
              hasSubmenu: false,
              nameTag: false,
              items: [],
            },
          ],
        },
        {
          title: "document_out",
          path: "",
          nameTag: false,
          name: "",
          icon: "mdi mdi-send",
          hasSubmenu: true,
          items: [
            {
              title: "document_list",
              path: "/suratKeluar",
              icon: "",
              badge: "document_out",
              name: "document_out",
              nameTag: false,
              hasSubmenu: false,
              items: [],
            },
            {
              title: "document_already_sent",
              path: "/documentAlreadySend",
              icon: "",
              name: "document_out",
              badge: "document_out_already",
              hasSubmenu: false,
              nameTag: false,
              items: [],
            },
            {
              title: "document_sent_out",
              path: "/suratKeluarTerkirim",
              icon: "",
              name: "document_out",
              badge: "document_out_send",
              hasSubmenu: false,
              nameTag: false,
              items: [],
            },
            {
              title: "document_rejected",
              path: "/documentRejected",
              icon: "",
              name: "document_out",
              nameTag: false,
              badge: "document_out_reject",
              hasSubmenu: false,
              items: [],
            },
            {
              title: "document_retracted",
              path: "/documentRetracted",
              icon: "",
              name: "document_out",
              nameTag: false,
              badge: "document_out_retract",
              hasSubmenu: false,
              items: [],
            },
          ],
        },
        {
          title: "Surat Digital",
          path: "",
          nameTag: false,
          name: "",
          icon: "mdi mdi-send",
          hasSubmenu: true,
          items: [
            {
              title: "Daftar Surat",
              path: "/drafting-file",
              icon: "",
              badge: "drafting",
              name: "drafting",
              nameTag: false,
              hasSubmenu: false,
              items: [],
            },
            {
              // title: "Surat Ditolak",
              title: "Surat Dibatalkan",
              path: "/drafting-file-declined",
              icon: "",
              badge: "drafting",
              name: "drafting",
              nameTag: false,
              hasSubmenu: false,
              items: [],
            },
            {
              title: "Surat Disetujui",
              path: "/drafting-file-approved",
              icon: "",
              badge: "drafting",
              name: "drafting",
              nameTag: false,
              hasSubmenu: false,
              items: [],
            },
            // {
            //   title: "Surat Dibatalkan",
            //   path: "/drafting-file-retracted",
            //   icon: "",
            //   badge: "drafting",
            //   name: "drafting",
            //   nameTag: false,
            //   hasSubmenu: false,
            //   items: [],
            // },
          ],
        },
        {
          title: "agenda",
          path: "/agenda",
          icon: "mdi mdi-calendar",
          name: "agenda",
          badge: "agenda",
          nameTag: false,
          hasSubmenu: false,
          items: [],
        },
        // {
        //   title: "meeting",
        //   path: "/meeting",
        //   icon: "mdi mdi-calendar",
        //   name: "agenda",
        //   badge: "agenda",
        //   nameTag: false,
        //   hasSubmenu: false,
        //   items: [],
        // },
        {
          title: "Meeting Room",
          path: "",
          nameTag: false,
          name: "",
          icon: "mdi mdi-calendar",
          hasSubmenu: true,
          items: [
            {
              title: "meeting",
              path: "/meeting",
              icon: "",
              name: "agenda",
              badge: "agenda",
              nameTag: false,
              hasSubmenu: false,
              items: [],
            },
            {
              title: "meeting_pesan",
              path: "/meetingPesan",
              icon: "",
              name: "agenda",
              badge: "agenda",
              nameTag: false,
              hasSubmenu: false,
              items: [],
            },
            {
              title: "rekap_pesan",
              path: "/rekapPesan",
              icon: "",
              name: "agenda",
              badge: "agenda",
              nameTag: false,
              hasSubmenu: false,
              items: [],
            },
            
          ],
        },
        {
          title: "dokumen_pergub",
          path: "/docPergub",
          icon: "mdi mdi-email",
          name: "docPergub",
          badge: "docPergub",
          nameTag: false,
          hasSubmenu: false,
          items: [],
        },
        // {
        //   title: "drafting",
        //   path: "",
        //   nameTag: false,
        //   name: "",
        //   icon: "mdi mdi-send",
        //   hasSubmenu: true,
        //   items: [
        //     {
        //       title: "drafting_list",
        //       path: "/drafting",
        //       icon: "",
        //       badge: "drafting",
        //       name: "drafting",
        //       nameTag: false,
        //       hasSubmenu: false,
        //       items: [],
        //     },
        //     {
        //       title: "drafting_list_declined",
        //       path: "/drafting-declined",
        //       icon: "",
        //       badge: "drafting",
        //       name: "drafting",
        //       nameTag: false,
        //       hasSubmenu: false,
        //       items: [],
        //     },
        //     {
        //       title: "drafting_list_approved",
        //       path: "/drafting-approved",
        //       icon: "",
        //       badge: "drafting",
        //       name: "drafting",
        //       nameTag: false,
        //       hasSubmenu: false,
        //       items: [],
        //     },
        //     {
        //       title: "drafting_list_retracted",
        //       path: "/drafting-retracted",
        //       icon: "",
        //       badge: "drafting",
        //       name: "drafting",
        //       nameTag: false,
        //       hasSubmenu: false,
        //       items: [],
        //     },
        //   ],
        // },

        {
          title: "Pencarian Dokument",
          path: "/pencarianDokumen",
          icon: "mdi mdi-email",
          hasSubmenu: false,
          cssClass: "",
          nameTag: false,
          name: "",
          items: [],
        },
        {
          title: "Agenda",
          path: "/agenda",
          icon: "mdi mdi-email",
          hasSubmenu: false,
          nameTag: false,
          cssClass: "",
          name: "",
          items: [],
        },
        {
          title: "report",
          path: "",
          icon: "mdi mdi-printer",
          hasSubmenu: true,
          nameTag: false,
          name: "",
          items: [
            // {
            //   title: "document_in",
            //   path: "/laporanDokumenIn",
            //   icon: "",
            //   hasSubmenu: false,
            //   nameTag: false,
            //   name: "document_in",
            //   items: []
            // },
            // {
            //   title: "disposition_in",
            //   path: "/laporanDispositionIn",
            //   icon: "",
            //   hasSubmenu: false,
            //   nameTag: false,
            //   name: "disposition_in",
            //   items: []
            // },
            // {
            //   title: "document_out",
            //   path: "/laporanDokumentKeluar",
            //   icon: "",
            //   hasSubmenu: false,
            //   nameTag: false,
            //   items: [],
            //   name: "document_out"
            // },
            {
              title: "laporan_docin",
              path: "/laporanDokumenIn",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              name: "laporan_docin",
              items: [],
            },
            {
              title: "laporan_dispoin",
              path: "/laporanDispositionIn",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              name: "laporan_dispoin",
              items: [],
            },
            {
              title: "laporan_docout",
              path: "/laporanDokumentKeluar",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              name: "laporan_docout",
              items: [],
            },
            {
              title: "laporan_disposended",
              path: "/laporanDispositionSended",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              name: "laporan_disposended",
              items: [],
            },
            {
              title: "laporan_smt",
              path: "/laporanSMT",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              name: "laporan_smt",
              items: [],
            },
            // {
            //   title: "disposisi_terproses",
            //   path: "/laporanDispositionSended",
            //   icon: "",
            //   hasSubmenu: false,
            //   nameTag: false,
            //   name: "disposisi_terproses",
            //   items: []
            // }
          ],
        },
        {
          title: "report_grafik",
          path: "",
          icon: "mdi mdi-chart-line",
          hasSubmenu: true,
          nameTag: false,
          name: "",
          items: [
            {
              title: "laporan_docin_grafik",
              path: "/laporanDokumenInGrafik",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              name: "laporan_docin_grafik",
              items: [],
            },
            {
              title: "laporan_dispoin_grafik",
              path: "/laporanDispositionInGrafik",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              name: "laporan_dispoin_grafik",
              items: [],
            },
            {
              title: "laporan_docout_grafik",
              path: "/laporanDokumentKeluarGrafik",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              name: "laporan_docout_grafik",
              items: [],
            },
          ],
        },
        {
          title: "arsip",
          path: "",
          icon: "mdi mdi-book",
          hasSubmenu: true,
          nameTag: false,
          name: "",
          items: [
            {
              title: "pemberkasan",
              path: "/pemberkasan",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              name: "pemberkasan",
              items: [],
            },
            {
              title: "daftarfolder",
              path: "/daftarfolder",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              name: "daftarfolder",
              items: [],
            },
            {
              title: "Berkas Simpan",
              path: "/folderdinilai",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              name: "folderdinilai",
              items: [],
            },
          ],
        },
        {
          title: "arsip_musnah",
          path: "",
          icon: "mdi mdi-book",
          hasSubmenu: true,
          nameTag: false,
          name: "",
          items: [
            {
              title: "daftareligiblemusnah",
              path: "/daftareligiblemusnah",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              name: "daftareligiblemusnah",
              items: [],
            },
            {
              title: "daftarusulmusnah",
              path: "/daftarusulmusnah",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              name: "daftarusulmusnah",
              items: [],
            },
            {
              title: "folderdimusnahkan",
              path: "/folderdimusnahkan",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              name: "folderdimusnahkan",
              items: [],
            },
          ],
        },
        {
          title: "arsip_permanen",
          path: "",
          icon: "mdi mdi-book",
          hasSubmenu: true,
          nameTag: false,
          name: "",
          items: [
            {
              title: "daftareligiblepermanen",
              path: "/daftareligiblepermanen",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              name: "daftareligiblepermanen",
              items: [],
            },
            {
              title: "daftarusulpermanen",
              path: "/daftarusulpermanen",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              name: "daftarusulpermanen",
              items: [],
            },
            {
              title: "folderdipermanenkan",
              path: "/folderdipermanenkan",
              icon: "",
              hasSubmenu: false,
              nameTag: false,
              name: "folderdipermanenkan",
              items: [],
            },
          ],
        },
        {
          title: "manual_book",
          path: "/manualBook",
          icon: "mdi mdi-email",
          name: "manualBook",
          badge: "manualBook",
          nameTag: false,
          hasSubmenu: false,
          items: [],
        },
        {
          title: "faq_user",
          path: "/faqUser",
          icon: "",
          hasSubmenu: false,
          nameTag: false,
          name: "faq_user",
          items: [],
        },
        // {
        //   title: "statistic",
        //   path: "",
        //   icon: "mdi mdi-chart-line",
        //   hasSubmenu: true,
        //   nameTag: false,
        //   name: "",
        //   items: [
        //     {
        //       title: "base_on_document_type",
        //       path: "/statistikJenisSurat",
        //       icon: "",
        //       hasSubmenu: false,
        //       nameTag: false,
        //       name: "document_out",
        //       items: []
        //     },
        //     {
        //       title: "base_on_document_nature",
        //       path: "/statistikSifatSurat",
        //       icon: "",
        //       hasSubmenu: false,
        //       nameTag: false,
        //       name: "document_out",
        //       items: []
        //     },
        //     {
        //       title: "base_on_document_target",
        //       path: "/statistikTujuanSurat",
        //       icon: "",
        //       hasSubmenu: false,
        //       nameTag: false,
        //       name: "document_out",
        //       items: []
        //     },
        //     {
        //       title: "base_on_document_find",
        //       path: "/statistikJumlahSurat",
        //       icon: "",
        //       hasSubmenu: false,
        //       nameTag: false,
        //       name: "document_out",
        //       items: []
        //     },
        //     {
        //       title: "base_on_next_document",
        //       path: "/statistikPencarianLanjut",
        //       icon: "",
        //       hasSubmenu: false,
        //       nameTag: false,
        //       name: "document_out",
        //       items: []
        //     }
        //   ]
        // }

        // {
        //   title: "Statistik Surat Keluar",
        //   path: "",
        //   icon: "mdi mdi-grafik",
        //   hasSubmenu: true,
        //   nameTag: false,
        //   name: "",
        //   items: [
        //     {
        //       title: "Berdasarkan Jenis Surat",
        //       path: "/statistikJenisSurat",
        //       icon: "",
        //       hasSubmenu: false,
        //       nameTag: false,
        //       name: "statistik_jenis_surat",
        //       items: []
        //     },
        //     // {
        //     //   title: "Berdasarkan Sifat Surat",
        //     //   path: "/statistikSifatSurat",
        //     //   icon: "",
        //     //   hasSubmenu: false,
        //     //   nameTag: false,
        //     //   name: "statistik_sifat_surat",
        //     //   items: []
        //     // },
        //     // {
        //     //   title: "Berdasarkan Tujuan Surat",
        //     //   path: "/statistikTujuanSurat",
        //     //   icon: "",
        //     //   hasSubmenu: false,
        //     //   nameTag: false,
        //     //   name: "statistik_tujuan_surat",
        //     //   items: []
        //     // },
        //     // {
        //     //   title: "Berdasarkan Jumlah Surat",
        //     //   path: "/statistikJumlahSurat",
        //     //   icon: "",
        //     //   hasSubmenu: false,
        //     //   nameTag: false,
        //     //   name: "statistik_jumlah_surat",
        //     //   items: []
        //     // },
        //     // {
        //     //   title: "Berdasarkan Pencarian Lanjut",
        //     //   path: "/statistikPencarianLanjut",
        //     //   icon: "",
        //     //   hasSubmenu: false,
        //     //   nameTag: false,
        //     //   name: "statistik_pencarian_lanjut",
        //     //   items: []
        //     // }
        //   ]
        // }
      ],
    };
  },
  methods: {
    changeUnit(id) {
      let data = {
        id: id,
      };
      axios
        .post("profile/active/unit", data)
        .then((res) => {
          this.$store.dispatch("profile/getProfile");
          let user = JSON.parse(localStorage.getItem("user"));
          user.unit = res.data.data.unit.name;
          user.unit_id = res.data.data.unit.id;
          localStorage.setItem("user", JSON.stringify(user));
          // eslint-disable-next-line no-unused-vars
          this.$router.push("/").catch((err) => {});
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          // eslint-disable-next-line no-unused-vars
          this.$router.push("/").catch((err) => {});
        });
    },
    changeRole(id) {
      axios.defaults.headers.common["Role"] = id;
      let user = JSON.parse(localStorage.getItem("user"));
      user.user_group_id = id;
      localStorage.setItem("user_group_id", id);
      localStorage.setItem("user", JSON.stringify(user));
      this.$store.dispatch("profile/getProfile");
      // this.$router.push('/');
    },
    async goToDetail(notif) {
      try {
        await axios.put(`/notifications/${notif.id}`);
        switch (notif.route) {
          case "document_out":
            this.$store.dispatch("suratKeluar/onDetail", notif.notifiable);
            break;

          case "document_in":
            this.$store.dispatch("documentIn/onDetail", notif.notifiable);
            break;

          case "disposition_in":
            this.$store.dispatch("dispositionIn/onDetail", notif.notifiable);
            break;

          case "disposition_send":
            this.$store.dispatch("dispositionSend/onDetail", notif.notifiable);
            break;

          case "document_out_ready":
            this.$store.dispatch(
              "documentAlreadySend/onDetail",
              notif.notifiable
            );
            break;

          case "drafting":
            this.$store.dispatch("drafting/onDetail", notif.notifiable);
            break;

          default:
            break;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },
    activeRole(id) {
      return id == localStorage.getItem("user_group_id");
    },
    activeUnit(id) {
      let user = this.user;
      return id == user.unit_id;
    },
    expandMenu() {
      this.isExpand = !this.isExpand;
      if (this.isExpand) {
        this.changeLogo = "none";
        document.body.className = "fix-header card-no-border mini-sidebar";
        this.heightMobile = "100%";
        this.styleSheet = "visible hidden";
      } else {
        this.changeLogo = "";
        document.body.className = "fix-header card-no-border";
        this.heightMobile = "98%";
        this.styleSheet = "auto";
      }
    },
    expandMenuMobile() {
      this.isExpand = !this.isExpand;
      if (this.isExpand) {
        this.changeLogo = "none";
        document.body.className = "fix-header card-no-border mini-sidebar";
        this.heightMobile = "100%";
        this.styleSheet = "visible hidden";
      } else {
        this.changeLogo = "none";
        document.body.className =
          "fix-header card-no-border mini-sidebar show-sidebar";
        this.heightMobile = "98%";
        this.styleSheet = "auto";
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    mobileCondition() {
      if (this.window.width <= 768) {
        this.$store.commit("mobileLoaded");
        this.changeLogo = "none";
        this.isExpand = true;
        document.body.className = "fix-header card-no-border mini-sidebar";
        this.heightMobile = "98%";
        this.styleSheet = "auto";
      }
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
    sisminkada() {
      window.open("http://103.131.105.2:2354/old2019/");
    },
    meeting_room() {
      window.open("http://103.131.105.2:2354/meeting-room/unit_list.php");
    },
    penghargaan() {
      window.open("http://103.131.105.2:2354/penghargaan/penghargaan_list.php");
    },
    bend26a() {
      window.open("http://103.131.105.2:2354/bend26a/login.php");
    },
    pustaka() {
      window.open("http://slim.sisminkada.net/data/login.php");
    },
    web_mail() {
      window.open("https://mail.jogjaprov.go.id/");
    },
    lampiran_skp() {
      window.open("http://103.131.105.2:2354/skp/login.php");
    },
    onChangeLanguage(locale) {
      this.$i18n.locale = locale;
    },
    showAlert() {
      alert("fitur ini hanya bisa di akses oleh admin");
    },
    findStatus(data) {
      return data.status === true;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.mobileCondition();
    // reference table not exist in database, for temp not execute actions
    // this.$store.dispatch("suratMemo/getSuratMemo", data);
  },
};
</script>

<style>
/* width */

#style-1 {
  overflow-y: scroll;
  scrollbar-width: thin;
  height: 100%;
}

#style-1::-webkit-scrollbar {
  width: 5px;
}

/* Track */
#style-1::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  overflow: hidden;
}

/* Handle on hover */
#style-1::-webkit-scrollbar-thumb:hover {
  background: #c3c6ca;
  overflow-y: scroll;
}

.tooltip-button {
  position: relative;
}

.tooltip-button .tooltiptext {
  visibility: hidden;
  width: 60px;
  font-size: 12px;
  background-color: #3e3e3e;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -30px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-button .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #3e3e3e transparent transparent transparent;
}

.tooltip-button:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.width-100 {
  width: 100px !important;
}

/* active combobox */
a.role.active {
  background: rgba(0, 0, 0, 0.25) !important;
  color: #fff !important;
  text-decoration: none !important;
}
a.role.active:hover {
  background: rgba(0, 0, 0, 0.75) !important;
  color: #fff !important;
  text-decoration: none !important;
}
/* active combobox */
a.unit.active {
  background: rgba(0, 25, 225, 0.25) !important;
  color: #fff !important;
  text-decoration: none !important;
}
a.unit.active:hover {
  background: rgba(0, 25, 225, 0.75) !important;
  color: #fff !important;
  text-decoration: none !important;
}
div.mailbox {
  width: 500px;
}
</style>
