<template>
  <div id="main-wrapper">
    <Header />
    <div
      v-if="!isMobile"
      style="
        position: relative;
        width: 100%;
        height: 70px;
        display: block;
        vertical-align: baseline;
        float: none;
      "
    ></div>
    <div class="page-wrapper" style="min-height: 586px">
      <router-view />
      <footer class="footer">
        © 2021 PAPERLESS OFFICE
      </footer>
    </div>
  </div>
</template>
<script>
import Header from "./header";
export default {
  components: {
    Header,
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
};
</script>
