<template>
  <ul id="sidebarnav">
    <template v-for="(row, index) in menuPermissions">
      <li v-if="row.cssClass === 'nav-small-cap'" :key="index" class="nav-small-cap">{{$t(row.title)}}</li>
      <li
        v-else
        @click="toggleMenuRow(index)"
        :class="[{ 'active': isVisibleMenuRow(index)}]"
        :key="index"
      >
        <a class="has-arrow" href="javascript:void(0);" v-if="row.path ===''">
          <i :class="row.icon"></i>
          <span class="hide-menu">{{$t(row.title)}}</span>&nbsp;
          <sup class="badge badge-warning" v-if="row.title.includes('drafting','drafting_file')">Beta</sup>
        </a>
        <router-link v-else :to="row.path">
          <i :class="row.icon"></i>
          <span class="hide-menu">{{$t(row.title)}}</span>
        </router-link>
        <ul
          :aria-expanded="isVisibleMenuRow(index)"
          v-if="row.hasSubmenu && (row.items.length > 0)"
          :class="['collapse',{'in':isVisibleMenuRow(index)}]"
          transition="collapsing"
        >
          <template v-for="(subMenu , indexOf) in row.items">
            <li
              v-if="subMenu.hasSubmenu && (subMenu.items.length > 0)"
              @click="toggleSubMenuRow(indexOf)"
              :key="indexOf"
            >
              <template v-if="subMenu.hasSubmenu && (subMenu.items.length > 0)">
                <router-link
                  class="has-arrow"
                  :aria-expanded="isVisibleSubMenuRow(indexOf)"
                  :to="subMenu.path"
                >
                  <i :class="['feather', subMenu.icon]"></i>
                  <span>{{$t(subMenu.title)}}</span>
                </router-link>
                <ul
                  :aria-expanded="isVisibleSubMenuRow(indexOf)"
                  :class="['collapse',{ 'in': visibleSubMenuRows.length > 0}]"
                  v-for="(superSubMenu , indexOf) in subMenu.items"
                  :key="indexOf"
                >
                  <li>
                    <router-link :to="superSubMenu.path">
                      <i :class="['feather', superSubMenu.icon]"></i>
                      <span>{{$t(superSubMenu.title)}}</span>
                    </router-link>
                  </li>
                </ul>
              </template>
            </li>
            <li :key="indexOf" v-else>
              <router-link :to="subMenu.path" :aria-expanded="isVisibleSubMenuRow(indexOf)">
                <i :class="['feather', subMenu.icon]"></i>
                <span>{{$t(subMenu.title)}}</span>
                <span v-if="subMenu.badge!==undefined && (badges[subMenu.badge]>0)" class="badge badge-warning float-right">{{ badges[subMenu.badge] }}</span>
              </router-link>
            </li>
          </template>
        </ul>
      </li>
    </template>
  </ul>
</template>
<script>
export default {
  props: {
    data: {
      required: true,
      type: Array
    }
  },
  computed: {
    badges(){
      return this.$store.state.profile.badges;
    },
    menuPermissions() {
      // eslint-disable-next-line
      this.menuAllowed = [];
      if (this.$store.state.profile.permissions) {
        this.data.forEach(element => {
          var permisions = Object.assign(
            {},
            this.$store.state.profile.permissions[element.name]
          );
          if (element.nameTag) {
            // eslint-disable-next-line
            this.menuAllowed.push(element);
          } else if (element.hasSubmenu && element.name === "") {
            var menu = {};
            if(element.badge!==undefined){
              menu = {
                title: element.title,
                path: element.path,
                nameTag: element.nameTag,
                name: element.name,
                icon: element.icon,
                badge: element.badge,
                hasSubmenu: element.hasSubmenu,
                items: this.subMenuAllowed(element.items)
              };
            }else{ 
              menu = {
                title: element.title,
                path: element.path,
                nameTag: element.nameTag,
                name: element.name,
                icon: element.icon,
                hasSubmenu: element.hasSubmenu,
                items: this.subMenuAllowed(element.items)
              };
            }
            if (menu.items.length > 0) {
              // eslint-disable-next-line
              this.menuAllowed.push(menu);
            }
          } else if (permisions.read) {
            // eslint-disable-next-line
            this.menuAllowed.push(element);
          }
        });
      }
      return this.menuAllowed;
    }
  },
  data() {
    return {
      visibleMenuRows: [],
      visibleSubMenuRows: [],
      openDropdownlvlTwo: false,
      menuAllowed: []
    };
  },
  methods: {
    showDropdownlvlTwo() {
      this.showAccordion = false;
      this.openDropdownlvlTwo = !this.openDropdownlvlTwo;
    },
    subMenuAllowed(val) {
      var subMenu = [];
      val.forEach(element => {
        var permisions = Object.assign(
          {},
          this.$store.state.profile.permissions[element.name]
        );
        if (permisions.read) {
          subMenu.push(element);
        }
      });
      return subMenu;
    },
    isVisibleMenuRow(rowId) {
      return this.visibleMenuRows.indexOf(rowId) >= 0;
    },
    showMenuRow(rowId) {
      if (!this.isVisibleMenuRow(rowId)) {
        this.visibleMenuRows.push(rowId);
      }
    },
    hideMenuRow(rowId) {
      if (this.isVisibleMenuRow(rowId)) {
        this.visibleMenuRows.splice(this.visibleMenuRows.indexOf(rowId));
      }
    },
    toggleMenuRow(rowId) {
      if (this.isVisibleMenuRow(rowId)) {
        this.hideMenuRow(rowId);
      } else {
        this.visibleMenuRows = [];
        this.showMenuRow(rowId);
      }
    },

    isVisibleSubMenuRow(rowId) {
      return this.visibleSubMenuRows.indexOf(rowId) >= 0;
    },
    showSubMenuRow(rowId) {
      if (!this.isVisibleSubMenuRow(rowId)) {
        this.visibleSubMenuRows.push(rowId);
      }
    },
    hideSubMenuRow(rowId) {
      if (this.isVisibleSubMenuRow(rowId)) {
        this.visibleSubMenuRows.splice(this.visibleMenuRows.indexOf(rowId));
      }
    },
    toggleSubMenuRow(rowId) {
      if (this.isVisibleSubMenuRow(rowId)) {
        this.hideSubMenuRow(rowId);
      } else {
        this.visibleMenuRows = [];
        this.visibleSubMenuRows = [];
        this.isVisibleMenuRow(rowId);
        this.showSubMenuRow(rowId);
      }
    }
  }
};
</script>
